<template>
    <div>
        <bread-crumb :navigationArray="navigationLink"/>
        <div class="white-container documents">
            <h2>Общественная экспертиза</h2>
            <tricolor-line class="tricolor_line"/>
            <div class="panel__tabs">
              <p v-for="(tab, index) in tabs" :class="{'active' : isActiveTab === index}" :key="`tab${index}`" @click="setTabNews(tab.route)">{{ tab.name }}</p>
            </div>

            <div v-if="thisYear" class="documents__filter">
              <custom-select key="inclusion" :title="thisYear" class="documents__filter-select" :list='dateYear' @InputsContent="setInclusion"
                         placeholder="год"/>
              <a href="https://old.oprf.ru/1449/2133/1537/views/2676/" target="_blank" class="documents__filter-link">Архив до 2020 года</a>
            </div>

          <documents-list v-for="item in arrayRes"
                          :key="`list${item.id}`"
                          class="documents__list"
                          :doc="item"
          />
        </div>
    </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb'
import DocumentsList from '@/components/ChamberNormativeBaseDocuments'
import CustomSelect from '@/components/forms/CustomSelect'
import TricolorLine from '@/components/TricolorLine'
import uniq from '@/methods/uniq'

export default {
  name: 'PublicExaminationOpinion',
  data () {
    return {
      isActiveTab: 2,
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Деятельность',
          path: '/public-examination'
        },
        {
          title: 'Экспертные заключения'
        }
      ],
      tabs: [
        { name: 'Новости', route: '/public-examination' },
        { name: 'Законопроекты для обсуждения', route: '/public-examination-bills' },
        { name: 'Экспертные заключения', route: '/public-examination-opinion' }
      ],
      arrayRes: [],
      documents_type: ['Доклады о состоянии гражданского общества', 'Специальные доклады', 'Рекомендации'],
      thisYear: 'Выбирите год',
      dateYear: [],
      systemTagList: ['/expert_opinions']
    }
  },
  methods: {
    async setDocuments (year) {
      const formdata = new FormData()
      if (year) {
        const dataSearch = [year]
        formdata.append('tagList', JSON.stringify(dataSearch))
      }
      formdata.append('systemTagList', JSON.stringify(this.systemTagList))
      let res = await fetch(`${this.$store.getters.getUrlApi}api/document/list`, {
        method: 'POST',
        body: formdata
      })
      res = await res.json()
      console.log(res.data.documents)
      let yearsArray = []
      res.data.documents.map(item => {
        item.tagList.map(data => {
          if (Number.isInteger(parseInt(data))) {
            yearsArray.push(parseInt(data))
          }
        })
      })
      yearsArray = uniq(yearsArray)
      if (!this.dateYear.length) {
        yearsArray = yearsArray.sort((a, b) => {
          return b - a
        })
        this.setInclusion({
          title: yearsArray[0]
        })
        yearsArray.map(item => {
          this.dateYear.push({ title: item })
        })
      }
      this.arrayRes = res.data.documents
    },
    setInclusion (val) {
      this.thisYear = val.title
      this.setDocuments(val.title)
    },
    setTabNews (route) {
      this.$router.push({ path: `${route}` })
    }
  },
  mounted () {
    // for (let i = 2020; i <= this.thisYear; i++) this.dateYear.push({ title: i })
    this.setDocuments()
    /*
    setTimeout(() => {
      this.setInclusion({
        title: 2022
      })
    }, 300)
    */
  },
  components: {
    BreadCrumb,
    DocumentsList,
    CustomSelect,
    TricolorLine
  }
}
</script>

<style lang="scss" scoped>

.white-container{
    margin: 0 3.8rem;
    padding: 2.5rem 2.94rem;
}

h2{
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
    margin-bottom: 2rem;
}
h3{
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-bottom: 1.375rem;
  color: #1F3245;
}
.tricolor_line {
  margin-bottom: 2rem;
}
  .panel__tabs {
    display: flex;
    padding-bottom: 1.375rem;
    border-bottom: .0625rem solid #D5D6D9;
    margin-bottom: 2rem;
    color: #003E78;

    p, .active {
      font-size: 0.8125rem;
      margin-right: 1.25rem;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      opacity: 0.6;

      cursor: pointer;
    }

    .active {
      font-weight: 600;
      color: #246CB7;
      opacity: 1;
    }

    .active::before {
      content: '';
      height: 100%;
      width: 100%;
      bottom: -1.45rem;
      position: absolute;
      border-bottom: 0.125rem solid #246CB7;
    }

  }
.documents {
  &__filter {
    margin-bottom: 1.875rem;
    display: inline-flex;
  }

  &__filter-select {
    width: 7.5rem;
  }

  &__filter-link {
      padding-left: 1.375em;
      display: flex;
      align-items: center;

      font-size: .75rem;
      line-height: 1.125rem;
      color: #003E78;
      opacity: 0.6;

    &::after {
      content: '';
      margin-left: 0.75rem;
      width: 0.7rem;
      height: 0.7rem;
      background-image: url('../../assets/icons/icon_link.svg');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  &__list {
    margin-bottom: 2.5rem;
  }
  &__active {
    font-weight: 600;
  }
}

@media screen and (max-width: 768px){
  .white-container{
    width: auto;
    margin: 0 .75rem;
    padding: 1.86rem 1.25rem;
  }

}
@media screen and (max-width: 420px){
    .white-container{
        width: 100%;
        margin: 0;
        padding: 1.88rem 1rem;
    }
    .panel__tabs {
      overflow-x: scroll;
      flex-wrap: nowrap;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      p{
        flex: 0 0 auto;
      }
      .active::before {
        content: '';
        height: 100%;
        width: 100%;
        bottom: -1.38rem;
      }
    }
}
</style>
